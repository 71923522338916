<template>
  <!--begin::details View-->
  <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
    <!--begin::Card header-->
    <div class="card-header cursor-pointer">
      <!--begin::Card title-->
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">Client Details</h3>
      </div>
      <!--end::Card title-->

      <!--begin::Action-->
      <router-link
        :to="{
          name: 'edit-client',
          params: {
            clientid: Number(clientid),
          },
        }"
        class="btn btn-primary align-self-center"
      >
        Edit Client</router-link
      >
      <!--end::Action-->
    </div>
    <!--begin::Card header-->

    <!--begin::Card body-->
    <div class="card-body p-9">
      <!--begin::Row-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">Company</label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8">
          <span class="fw-bolder fs-6 text-dark">{{ clientInfo.name }}</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->

      <!--begin::Input group-->
      <div class="row mb-7">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">Primary Contact</label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8 fv-row">
          <span class="fw-bold fs-6">{{ clientInfo.primaryContactName }}</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Input group-->

      <!--begin::Input group-->
      <div class="row mb-3">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">Primary Contact Email</label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8 fv-row">
          <span class="fw-bold fs-6">
            <a :href="'mailto:' + clientInfo.primaryContactEmail">
              {{ clientInfo.primaryContactEmail }}
            </a></span
          >
        </div>
        <!--end::Col-->
      </div>
      <!--end::Input group-->
      <!--begin::Input group-->
      <div
        class="row mb-3"
        v-if="
          currentUser.role === 'SuperAdmin' ||
          currentUser.role === 'LicensedDistributor'
        "
      >
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">Content Expert</label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8 fv-row">
          <p
            v-if="clientInfo.primaryContentExpert === 'Not assigned.'"
            class="fw-bold fs-6 text-danger"
          >
            <span style="font-size: 13px; font-weight: bold"
              >Primary Content Expert needs to be assigned!</span
            >
          </p>
          <p v-else class="fw-bold fs-6">
            {{ clientInfo.primaryContentExpert }}
          </p>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Input group-->
      <!--begin::Input group-->
      <div class="row mb-3">
        <!--begin::Col-->
        <div class="col-lg-12 fv-row">
          <hr />
          <h4 class="fw-bolder m-0 text-muted">Primary Location</h4>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Input group-->
      <!--begin::Input group-->
      <div class="row mb-4">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">Phone #</label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8 fv-row">
          <span class="fw-bold fs-6">{{ primaryLocation.phoneNumber }}</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Input group-->
      <!--begin::Input group-->
      <div class="row mb-4">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">Address</label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8 fv-row">
          <span class="fw-bold fs-6">{{ primaryLocation.streetAddress }}</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Input group-->
      <!--begin::Input group-->
      <div class="row mb-4">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">Address 2</label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8 fv-row">
          <span class="fw-bold fs-6">{{ primaryLocation.streetAddress2 }}</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Input group-->
      <!--begin::Input group-->
      <div class="row mb-4">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">City</label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8 fv-row">
          <span class="fw-bold fs-6">{{ primaryLocation.city }}</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Input group-->
      <!--begin::Input group-->
      <div class="row mb-4">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">State</label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8 fv-row">
          <span class="fw-bold fs-6">{{ primaryLocation.state }}</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Input group-->
      <!--begin::Input group-->
      <div class="row mb-4">
        <!--begin::Label-->
        <label class="col-lg-4 fw-bold text-muted">Postal Code</label>
        <!--end::Label-->

        <!--begin::Col-->
        <div class="col-lg-8 fv-row">
          <span class="fw-bold fs-6">{{ primaryLocation.postalCode }}</span>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Input group-->
    </div>
    <!--end::Card body-->
  </div>
  <!--end::details View-->
</template>

<script lang="ts">
import { defineComponent, onMounted, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";

export default defineComponent({
  name: "incline-client-overview",
  props: ["clientid"],
  components: {},
  setup(props) {
    const store = useStore();
    const currentUser = store.getters.currentUser;
    console.log("clientid: " + props.clientid);
    const clientInfo = computed(() => {
      return store.getters.getClientInfo(Number(props.clientid));
    });

    const primaryLocation = computed(() => {
      return store.getters.getPrimaryLocation(Number(props.clientid));
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs("Overview", ["Client"]);
    });

    return {
      currentUser,
      clientInfo,
      primaryLocation,
    };
  },
});
</script>
