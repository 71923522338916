
import { defineComponent, onMounted, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";

export default defineComponent({
  name: "incline-client-overview",
  props: ["clientid"],
  components: {},
  setup(props) {
    const store = useStore();
    const currentUser = store.getters.currentUser;
    console.log("clientid: " + props.clientid);
    const clientInfo = computed(() => {
      return store.getters.getClientInfo(Number(props.clientid));
    });

    const primaryLocation = computed(() => {
      return store.getters.getPrimaryLocation(Number(props.clientid));
    });

    onMounted(() => {
      setCurrentPageBreadcrumbs("Overview", ["Client"]);
    });

    return {
      currentUser,
      clientInfo,
      primaryLocation,
    };
  },
});
